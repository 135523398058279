import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getBuildingsMap} from "../../../../selectors/buildingsSelector";
import {makeGetDevicesByType} from "../../../../selectors/deviceSelector";
import {DevType} from "../../../../constans/devices";
import Card from "../../../../components/basics/card/Card";
import Stepper from "../../../../components/basics/stepper/Stepper";
import Step from "../../../../components/basics/stepper/Step";
import {ReactSVG} from "react-svg";
import StartCalibration from "../../../../resources/images/water-calibration/calibration_start.svg";
import Button from "../../../../components/basics/button/Button";
import {Col, Row} from "react-bootstrap";
import {Field, FieldArray, formValueSelector, reduxForm} from "redux-form";
import ReduxLabeledSelect from "../../../../components/basics/select/labeled-select/ReduxLabeledSelect";
import PipeWithBucket from "../../../../resources/images/water-calibration/pipe.svg";
import {getLocationNameByBuildingMapSelector, getPlcmntIDsByDevice} from "../../../../utils/BuildingUtils";
import {toPrettyHex} from "../../../../utils/TextUtils";
import TestWater from "./TestWater";
import {isNumber} from "lodash";
import {withTranslation} from "react-i18next";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import ChooseDestination from "./ChooseDestination";
import ReduxLabeledSlider from "../../../../components/basics/slider/labeled-slider/ReduxLabeledSlider";
import LoadingComponent from "../../../../components/loading/LoadingComponent";
import ReduxSwitch from "../../../../components/basics/switch/ReduxSwitch";
import {convertVolumeUnitTo} from "../../../../utils/UnitUtils";
import {UnitTypes} from "../../../../constans/unitTypes";

const FormName = "settings-feeding-water-calibration-form"

const selector = formValueSelector(FormName);

const TestImpulses = 40;
const RunImpulses = 400;

const TestTime = 3000;
const RunTime = 15000;

const NormalTime = 60000;
const NormalImpulsesVolume = 1000;


function makeMapStateToProps() {
    return function mapStateToProps(state) {
        const getDevicesByType = makeGetDevicesByType();
        return {
            dispenser: selector(state, "dispenser"),
            efficiencyList: selector(state, "efficiencyList"),
            useImpulse: selector(state, "useImpulse"),
            buildingsMap: getBuildingsMap(state),
            dispensers: getDevicesByType(state, {DevType: [DevType.DISPENSER_NRF]})
        }
    };
}

class WaterCalibration extends Component {

    state = {
        step: 1
    }

    constructor(props) {
        super(props);
        const {initialize} = this.props;
        initialize({
            efficiencyList: [0]
        })
    }

    handleStepClick = (step = 1) => {
        this.setState({
            step: step
        })
    }

    onNext = () => {
        this.setState(state => ({
            step: state.step + 1
        }));
    }

    button = () => {
        const {dispenser, efficiencyList, useImpulse, initialized} = this.props;
        const {step} = this.state;
        const efficiency = initialized ? efficiencyList.reduce((a, b) => (a || 0) + (b || 0), 0) / Math.max(1, efficiencyList.length) : 0;
        const convertedEfficiency = useImpulse ? this.convertToImpulsesPerLiter(efficiency) : this.convertToLitersPer60Seconds(efficiency);
        const disabled = !![
            false,
            !dispenser,
            false,
            !isNumber(convertedEfficiency) || convertedEfficiency <= 0
        ][step - 1]
        return (
            <ButtonGroup fixed>
                <Button
                    buttonColor={'success'}
                    buttonStyle={'round'}
                    disabled={disabled}
                    icon={<i className={'fas fa-arrow-right'}/>}
                    onClick={this.onNext}
                />
            </ButtonGroup>

        )
    }

    options = () => {
        const {dispensers, buildingsMap, useImpulse} = this.props;
        const options = [];
        dispensers.forEach(dispenser => {
            const locations = getPlcmntIDsByDevice({device: dispenser});
            if (locations.length === 1) {
                const name = `${getLocationNameByBuildingMapSelector(buildingsMap, locations[0])} | ${toPrettyHex(dispenser.Address)}`;
                if (dispenser.hasWater() && (!useImpulse || (useImpulse && dispenser.hasFlowMeter())))
                    options.push({
                        name: name,
                        value: {
                            DevID: dispenser.DevID,
                            placementId: locations[0],
                            name: name
                        }
                    })
            }
        })
        return options;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {change, useImpulse} = this.props;
        if (prevProps.useImpulse !== useImpulse) {
            change("dispenser", null)
        }
    }

    convertToLitersPer60Seconds = (value) => {
        return value * (NormalTime / RunTime)
    }

    convertToImpulsesPerLiter = (value) => {
        return Math.round(NormalImpulsesVolume * (RunImpulses / Math.max(1, value)))
    }

    formatter = (value) => {
        return convertVolumeUnitTo(value, {
            unit: UnitTypes.MEDIUM,
            fixed: 2,
            showUnit: true
        });
    }

    efficiencyFormatter = (value) => {
        const {useImpulse, t} = this.props;
        if (useImpulse) {
            return t("newSettings.feeding.waterCalibration.efficiencyPerLiter", {impulses: this.convertToImpulsesPerLiter(value)});
        }
        return t("newSettings.feeding.waterCalibration.efficiencyPer60Seconds", {
            efficiency: convertVolumeUnitTo(this.convertToLitersPer60Seconds(value), {
                unit: UnitTypes.MEDIUM,
                fixed: 2,
                showUnit: true
            })
        });
    }

    renderEfficiency = ({fields, efficiency, meta: {touched, error, submitFailed}}) => {
        const {t} = this.props;
        return (<>
            {
                fields.map((member, index) => (
                    <Field
                        name={member}
                        id={member}
                        label={t("newSettings.feeding.waterCalibration.measurement", {number: index + 1})}
                        component={ReduxLabeledSlider}
                        min={0}
                        valueFormatter={this.formatter}
                        max={15000}
                        step={10}
                    />
                ))
            }
            {t("newSettings.feeding.waterCalibration.averageEfficiency", {
                efficiency: this.efficiencyFormatter(efficiency),
                count: fields.length
            })}
            <Row>
                <Col lg={6}>
                    <Button
                        onClick={() => (fields.length > 1) && fields.remove(fields.length - 1)}
                        type={"button"}
                        disabled={fields.length <= 1}
                        className={"w-100 mb-0"}
                        buttonStyle={"text"}
                        buttonColor={"error"}
                        icon={<i className={"fas fa-trash"}/>}>
                        {t("newSettings.feeding.waterCalibration.deleteLast")}
                    </Button>
                </Col>
                <Col lg={6}>
                    <Button
                        onClick={() => fields.push(0)}
                        type={"button"}
                        className={"w-100 mb-0"}
                        buttonStyle={"text"}
                        icon={<i className={"fas fa-plus"}/>}>
                        {t("newSettings.feeding.waterCalibration.newMeasurement")}
                    </Button>
                </Col>
            </Row>


        </>)
    }

    render() {
        const {t, dispenser, initialized, efficiencyList, useImpulse} = this.props;
        const efficiency = initialized ? efficiencyList.reduce((a, b) => (a || 0) + (b || 0), 0) / Math.max(1, efficiencyList.length) : 0;
        const convertedEfficiency = useImpulse ? this.convertToImpulsesPerLiter(efficiency) : this.convertToLitersPer60Seconds(efficiency) / 2; //urzadzenie chce co 30s kek
        const {step} = this.state;
        const options = this.options();
        const loadingComponent = () => <svg viewBox="0 0 270.93334 270.93334"/>;
        return (
            <div>
                <LoadingComponent isLoading={!initialized}/>
                <Card>
                    <Stepper activeStep={step} onClickStep={this.handleStepClick}>
                        <>
                            <Step label={t("newSettings.feeding.waterCalibration.beginning")}/>
                            <Step label={t("newSettings.feeding.waterCalibration.chooseDispenser")}/>
                            <Step label={t('newSettings.feeding.waterCalibration.prepareDispenser')}/>
                            <Step label={t("calibration")}/>
                            <Step label={t("summary")}/>
                        </>
                    </Stepper>
                    {
                        step === 1 &&
                        <>
                            <div className={'calibration'}>
                                <div className={'calibration-container'}>
                                    <ReactSVG src={StartCalibration}
                                              loading={loadingComponent}/>
                                </div>
                                <p>{t("newSettings.feeding.waterCalibration.prepareBucketAndScale")}</p>
                                {this.button()}
                            </div>
                        </>
                    }
                    {
                        step === 2 &&
                        <>
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <TestWater useImpulse={useImpulse} impulses={TestImpulses}
                                               device={dispenser || undefined} time={TestTime}>
                                        <Field
                                            name="useImpulse"
                                            id={"useImpulse"}
                                            label={t("newSettings.feeding.waterCalibration.useImpulse")}
                                            options={options}
                                            component={ReduxSwitch}
                                        />
                                        <Field
                                            name="dispenser"
                                            id={"dispenser"}
                                            required
                                            label={t("location")}
                                            options={options}
                                            component={ReduxLabeledSelect}
                                        />
                                        {
                                            useImpulse ?
                                                <p>{t("newSettings.feeding.waterCalibration.testRunImpulse", {count: TestImpulses})}</p>
                                                :
                                                <p>{t("newSettings.feeding.waterCalibration.testRun", {count: Math.floor(TestTime / 1000)})}</p>
                                        }
                                    </TestWater>
                                </Col>
                            </Row>
                            {this.button()}
                        </>
                    }
                    {
                        step === 3 &&
                        <>
                            <div className={'calibration'}>
                                <div className={'calibration-container'}>
                                    <ReactSVG src={PipeWithBucket} loading={loadingComponent}/>
                                </div>
                                <p>{t("newSettings.feeding.waterCalibration.putBucketUnderPipe")}</p>
                                {this.button()}
                            </div>
                        </>
                    }
                    {
                        step === 4 &&
                        <>
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <TestWater useImpulse={useImpulse} impulses={RunImpulses}
                                               device={dispenser || undefined} time={RunTime}>
                                        {
                                            useImpulse ?
                                                <p>{t("newSettings.feeding.waterCalibration.runCalibrationImpulse", {count: RunImpulses})}</p>
                                                :
                                                <p>{t("newSettings.feeding.waterCalibration.runCalibration", {count: Math.floor(RunTime / 1000)})}</p>
                                        }
                                    </TestWater>
                                    <FieldArray efficiency={efficiency} component={this.renderEfficiency}
                                                name={"efficiencyList"} rerenderOnEveryChange/>

                                    {this.button()}
                                </Col>
                            </Row>
                        </>
                    }
                    {
                        step === 5 && !!dispenser && !!convertedEfficiency &&
                        <>
                            <ChooseDestination useImpulse={!!useImpulse} efficiency={convertedEfficiency}
                                               DevID={dispenser.DevID}
                                               placementId={dispenser.placementId}/>
                        </>
                    }
                </Card>
            </div>
        );
    }
}

WaterCalibration = reduxForm({
    form: FormName,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
})(WaterCalibration);

WaterCalibration = connect(
    makeMapStateToProps,
)(WaterCalibration);

export default withTranslation()(WaterCalibration)
