import React from "react";
import InfoBox from "../../../../components/basics/info-box/InfoBox";
import Timer from "react-time-counter";
import Button from "../../../../components/basics/button/Button";
import {startWater, startWaterImpulses, stopWater} from "../../../../IOT/device-functions/DispenserNRFFunctions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {show} from "redux-modal";
import {withTranslation} from "react-i18next";
import {get} from "lodash";

export class TestWater extends React.Component {

    seconds = Math.floor(this.props.time / 1000);

    state = {
        running: false
    }

    setRunning = () => {
        this.setState({running: true})
    }

    stopRunning = () => {
        this.setState({running: false})
    }
    start = ({DevID, useImpulse} = {}) => {
        const {time, impulses} = this.props;
        if (DevID) {
            if (useImpulse) {
                startWaterImpulses(DevID, {impulses}, {onSuccess: this.handleEnd, onFailure: this.stopRunning})
            } else {
                startWater(DevID, {time}, {onSuccess: this.setRunning, onFailure: this.stopRunning});
            }
        }
    }

    stop = ({DevID, useImpulse} = {}) => {
        if (DevID) {
            stopWater(DevID, {onSuccess: this.setRunning, onFailure: this.stopRunning});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //jesli zmienimy deivce w trakcie wydawania wody niech sie zatrzyma
        const {device} = this.props;
        const {running} = this.state;
        const DevID = get(prevProps.device, "DevID")
        const newDevID = get(device, "DevID")
        if (DevID !== newDevID && (DevID) && (running)) {
            this.stop({DevID, useImpulse: prevProps.useImpulse})
        }
    }

    handleEnd = () => {
        this.setState({
            running: false
        })
    }

    toggleStart = () => {
        const {running} = this.state;
        const {device, useImpulse} = this.props;
        if (device) {
            if (running) {
                this.stop({DevID: device.DevID, useImpulse});
            } else {
                this.start({DevID: device.DevID, useImpulse});
            }
        }
    }

    render() {
        const {seconds} = this;
        const {running} = this.state;
        const {children, t, device} = this.props;
        return (
            <div>
                {
                    !!running &&
                    <InfoBox boxColor={"info"}>
                        <Timer
                            backward={true}
                            seconds={seconds}
                            showHours={false}
                            showMinutes={false}
                            label={t("newSettings.feeding.waterCalibration.testWater.running")}
                            endLabel={" s"}
                            callback={this.handleEnd}
                        />
                    </InfoBox>
                }
                {children}
                <Button
                    className={"w-100"}
                    type={"button"}
                    disabled={!device}
                    onClick={this.toggleStart}
                >
                    {t(`newSettings.feeding.waterCalibration.testWater.${running ? "stop" : "start"}`)}
                </Button>
            </div>
        )
    }
}

TestWater.defaultProps = {
    time: 5000,
    impulses: 400,
    useImpulse: false
};

TestWater = connect(
    null,
    dispatch => bindActionCreators({show}, dispatch)
)(TestWater);

export default withTranslation()(TestWater);

