import React, {Component} from 'react';
import {connect} from 'react-redux';
import {formValueSelector, reduxForm} from "redux-form";
import {withTranslation} from "react-i18next";
import {makeGetClosestPlacementByID} from "../../../../selectors/buildingsSelector";
import {Level} from "../../../../constans/levelTypes";
import ButtonGroup from "../../../../components/basics/button/button-group/ButtonGroup";
import Button from "../../../../components/basics/button/Button";
import {Col, Row} from "react-bootstrap";
import Card from "../../../../components/basics/card/Card";
import {withRouter} from "react-router-dom";
import {makeGetDeviceByID} from "../../../../selectors/deviceSelector";
import {
    setWaterEfficiency,
    setWaterEfficiencyNotificationFailure,
    setWaterEfficiencyNotificationSuccess
} from "../../../../api/devices/waterEfficiency";

const ModalName = 'settings-feeding-water-calibration-destination';

const selector = formValueSelector(ModalName);

function makeMapStateToProps(initialState, initialProps) {
    const params = {DevID: initialProps.DevID};
    if (initialProps.placementId) {
        params.level = Level.CHAMBER;
        params.id = initialProps.placementId;
    }
    const getDeviceByID = makeGetDeviceByID();
    const getClosestPlacementByID = makeGetClosestPlacementByID();
    return function mapStateToProps(state, props) {
        return {
            farm: state.location.farm,
            device: getDeviceByID(state, params),
            chamber: getClosestPlacementByID(state, params),
            type: selector(state, "type"),
            actions: selector(state, "actions")
        };
    }
}


class ChooseDestination extends Component {

    constructor(props) {
        super(props);
        const {initialize} = this.props;
        initialize({
            type: "device"
        })
    }

    onChange = (type = "device") => {
        const {change} = this.props;
        change("type", type);
    }

    render() {
        const {device, chamber, type, handleSubmit, submitting, t} = this.props;
        const size = 12 / (1 + +!!device + +!!chamber);
        return (
            <form onSubmit={handleSubmit}>
                {t("newSettings.feeding.waterCalibration.chooseDestination")}
                <Row>
                    {
                        !!device &&
                        <Col className={"pointer"} lg={size} onClick={() => this.onChange("device")}>
                            <Card shadow={false} className={"h-100"} selected={type === "device"}>
                                <h5> {t("newSettings.feeding.waterCalibration.setOnDevice")}</h5>
                                <div className={"opacity-50 text-wrap"}>{device.Name}</div>
                            </Card>
                        </Col>
                    }
                    {
                        !!chamber && <Col className={"pointer"} lg={size} onClick={() => this.onChange("chamber")}>
                            <Card shadow={false} className={"h-100"} selected={type === "chamber"}>
                                <h5> {t("newSettings.feeding.waterCalibration.setOnChamber")}</h5>
                                <div
                                    className={"opacity-50 text-wrap"}>{chamber.name.join(" - ") || "?"}</div>
                            </Card>

                        </Col>
                    }
                    <Col className={"pointer"} lg={size} onClick={() => this.onChange("all")}>
                        <Card shadow={false} className={"h-100"} selected={type === "all"}>
                            <h5> {t("newSettings.feeding.waterCalibration.setOnAll")}</h5>
                            <div
                                className={"opacity-50 text-wrap"}>{t("newSettings.feeding.waterCalibration.setOnAllText")}</div>
                        </Card>
                    </Col>
                    {
                        // tutaj dorobic silos jak bedzie na froncie
                    }
                </Row>

                <ButtonGroup fixed renderInPortal={false}>
                    <Button
                        isLoading={submitting}
                        buttonStyle="round"
                        buttonColor="success"
                        icon={<i className="fas fa-plus"/>}
                    />
                </ButtonGroup>
            </form>
        );
    }
}

const submit = (values, dispatch, props) => {
    const {device, chamber, efficiency, useImpulse, farm, history} = props;
    const {type} = values;
    const params = {Efficiency: +efficiency, Type: +useImpulse};
    switch (type) {
        case "all":
            params.AllDevices = true;
            break;
        case "chamber":
            params.PlcmntIDs = [chamber.id];
            break;
        case "device":
            params.DevIDs = [device.DevID];
            break;
        default:
            console.error("this should not happen")
            break;
    }
    return setWaterEfficiency(params).then(res => {
        setWaterEfficiencyNotificationSuccess(res);
        history.push(`/${farm}/settings`);
    }).catch(err => {
        setWaterEfficiencyNotificationFailure(err);
    })
}


ChooseDestination = reduxForm({
    form: ModalName,
    onSubmit: submit,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true
})(ChooseDestination);

ChooseDestination = connect(
    makeMapStateToProps,
)(ChooseDestination);

ChooseDestination = withRouter(ChooseDestination);
export default withTranslation()(ChooseDestination);
